<template>
  <div class="home">
    <h1>Pintor profesional. Artistico</h1>
    <p>Realizó trabajos artísticos de garantia, escultura.
      tematizacion, imitación mármol, estuco veneciano los
      mejores precios de el mercado con una calidad
      y acabados perfectos.</p>
    <p>Pintamos su piso,chalet,local, habitación,cocina
      o cualquier superficie pintable. llamenos presupuesto
      gratis y sin ningún compromiso, estaremos encantados
      en atenderle</p>
    <h2 style="margin-bottom: 0">Abel</h2>
    <p style="margin-top: 10px; margin-bottom: 40px"><strong>Teléfono: 676 73 07 11</strong></p>
    <vue-picture-swipe :items="items"></vue-picture-swipe>
  </div>
</template>

<script>
// @ is an alias to /src
import VuePictureSwipe from 'vue-picture-swipe';

export default {
  name: 'Gallery of Abel',
  components: {
    VuePictureSwipe,
  },
  data() {
    return {
      items: [
        {
          src: require('@/assets/img10.jpeg'),
          thumbnail: require('@/assets/thumb-img10.jpeg'),
          w: 720,
          h: 935,
        },
        {
          src: require('@/assets/img09.jpeg'),
          thumbnail: require('@/assets/thumb-img09.jpeg'),
          w: 720,
          h: 939,
        },
        {
          src: require('@/assets/img08.jpeg'),
          thumbnail: require('@/assets/thumb-img08.jpeg'),
          w: 720,
          h: 954,
        },
        {
          src: require('@/assets/img03.jpeg'),
          thumbnail: require('@/assets/thumb-img03.jpeg'),
          w: 1200,
          h: 1600,
        },
        {
          src: require('@/assets/img13.jpeg'),
          thumbnail: require('@/assets/thumb-img13.jpeg'),
          w: 720,
          h: 953,
        },
        {
          src: require('@/assets/img07.jpeg'),
          thumbnail: require('@/assets/thumb-img07.jpeg'),
          w: 720,
          h: 1031,
        },
        {
          src: require('@/assets/img20.jpeg'),
          thumbnail: require('@/assets/thumb-img20.jpeg'),
          w: 640,
          h: 472,
        },
        {
          src: require('@/assets/img18.jpeg'),
          thumbnail: require('@/assets/thumb-img18.jpeg'),
          w: 1600,
          h: 1200,
        },
        {
          src: require('@/assets/img19.jpeg'),
          thumbnail: require('@/assets/thumb-img19.jpeg'),
          w: 1200,
          h: 1600,
        },
        {
          src: require('@/assets/img05.jpeg'),
          thumbnail: require('@/assets/thumb-img05.jpeg'),
          w: 2048,
          h: 1536,
        },
        {
          src: require('@/assets/img01.jpeg'),
          thumbnail: require('@/assets/thumb-img01.jpeg'),
          w: 720,
          h: 544,
        },
        {
          src: require('@/assets/img14.jpeg'),
          thumbnail: require('@/assets/thumb-img14.jpeg'),
          w: 720,
          h: 528,
        },
        {
          src: require('@/assets/img15.jpeg'),
          thumbnail: require('@/assets/thumb-img15.jpeg'),
          w: 1536,
          h: 2048,
        },
        {
          src: require('@/assets/img16.jpeg'),
          thumbnail: require('@/assets/thumb-img16.jpeg'),
          w: 1663,
          h: 936,
        },
        {
          src: require('@/assets/img06.jpeg'),
          thumbnail: require('@/assets/thumb-img06.jpeg'),
          w: 2048,
          h: 1536,
        },
        {
          src: require('@/assets/img02.jpeg'),
          thumbnail: require('@/assets/thumb-img02.jpeg'),
          w: 1024,
          h: 768,
        },
        {
          src: require('@/assets/img11.jpeg'),
          thumbnail: require('@/assets/thumb-img11.jpeg'),
          w: 2008,
          h: 1504,
        },
        {
          src: require('@/assets/img17.jpeg'),
          thumbnail: require('@/assets/thumb-img17.jpeg'),
          w: 1792,
          h: 1382,
        },
        {
          src: require('@/assets/img12.jpeg'),
          thumbnail: require('@/assets/thumb-img12.jpeg'),
          w: 720,
          h: 535,
        },
        {
          src: require('@/assets/img21.jpeg'),
          thumbnail: require('@/assets/thumb-img21.jpeg'),
          w: 1016,
          h: 1600,
        },
        {
          src: require('@/assets/img28.jpeg'),
          thumbnail: require('@/assets/thumb-img28.jpeg'),
          w: 401,
          h: 712,
        },
        {
          src: require('@/assets/img22.jpeg'),
          thumbnail: require('@/assets/thumb-img22.jpeg'),
          w: 1600,
          h: 1200,
        },
        {
          src: require('@/assets/img23.jpeg'),
          thumbnail: require('@/assets/thumb-img23.jpeg'),
          w: 720,
          h: 909,
        },
        {
          src: require('@/assets/img24.jpeg'),
          thumbnail: require('@/assets/thumb-img24.jpeg'),
          w: 1250,
          h: 720,
        },
        {
          src: require('@/assets/img25.jpeg'),
          thumbnail: require('@/assets/thumb-img25.jpeg'),
          w: 720,
          h: 1440,
        },
        {
          src: require('@/assets/img26.jpeg'),
          thumbnail: require('@/assets/thumb-img26.jpeg'),
          w: 720,
          h: 949,
        },
        {
          src: require('@/assets/img27.jpeg'),
          thumbnail: require('@/assets/thumb-img27.jpeg'),
          w: 1600,
          h: 1198,
        },
        {
          src: require('@/assets/img29.jpeg'),
          thumbnail: require('@/assets/thumb-img29.jpeg'),
          w: 720,
          h: 1440,
        },
      ],
    };
  },
};
</script>
<style>
html {
  background: #bebebe;
  background: linear-gradient(180deg, #eee 0%,#fff 100%);
  min-height: 100vh;
}
</style>
